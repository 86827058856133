<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div
      v-if="allowAccess"
      class="auth-inner py-2"
    >

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            닥터인포 관리자
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          닥터인포 관리자 페이지 입니다.
        </b-card-title>
        <b-card-text class="mb-2">
          사내에서만 접속 가능하도록 제한되어 있습니다.<br>
          등록된 사용자는 UBWare 계정을 통해 로그인 가능합니다.
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
            <!-- Id -->
            <b-form-group
              label-for="Id"
              label="Id"
            >
              <validation-provider
                #default="{ errors }"
                name="Id"
                rules="required"
              >
                <b-form-input
                  id="Id"
                  v-model="userId"
                  name="login-Id"
                  :state="errors.length > 0 ? false:null"
                  placeholder="유비웨어 계정 입력"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <!-- <b-link :to="{name:'auth-forgot-password-v1'}">
                  <small>Forgot Password?</small>
                </b-link> -->
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    type="password"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!-- <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group> -->

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              style="margin-bottom:15px"
            >
              Sign in
            </b-button>
          </b-form>
        </validation-observer>

        <!-- <b-card-text class="text-center mt-2">
          <span>New on our platform? </span>
          <b-link :to="{name:'auth-register-v1'}">
            <span>Create an account</span>
          </b-link>
        </b-card-text> -->

      </b-card>
      <!-- /Login v1 -->
    </div>

    <div
      v-else-if="allowAccess === false"
      class="py-2"
    >
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-card-text class="px-5 py-5">
          본 사이트는 유비케어 사내에서만 접근이 가능합니다.<br>
          관련 문의사항은 유비케어 전략개발팀으로 문의 주시기 바랍니다.
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import axios from '@axios'
import { ref, getCurrentInstance, onMounted } from '@vue/composition-api'
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BButton, BForm, BFormInput, BFormGroup, BLink, BCardTitle, BCardText, BInputGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { errorFormatter } from '@core/utils/filter'
import { getHomeRouteForLoggedInUser, getInternal } from '@/auth/utils'

export default {
  components: {
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    const allowAccess = ref(null)

    onMounted(async () => {
      try {
        allowAccess.value = await getInternal()
      } catch (error) {
        alert(errorFormatter(error, `오류 메시지:${error}`))
        allowAccess.value = null
      }
    })

    const toast = useToast()
    const instance = getCurrentInstance()
    const refs = instance.proxy.$refs
    const router = instance.proxy.$router

    const status = ref('')
    const password = ref('')
    const userId = ref('')

    const login = () => {
      refs.loginForm.validate().then(success => {
        if (success) {
          axios.post('/fa/auth/login', {
            id: userId.value,
            password: password.value,
          })
            .then(rs => {
              const { userData } = rs.data
              // console.log(userData)
              localStorage.setItem('userData', JSON.stringify(userData))

              axios.get('/fa/auth/is-login').then(x => {
                if (x.data && x.data.isLogin) {
                  // move main dashboard
                  router.replace(getHomeRouteForLoggedInUser(userData.role))
                    .then(() => {
                      toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Welcome ${userData.userName}`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: `현재 사용자 권한은 ${userData.role}으로 로그인 되었습니다.`,
                        },
                      })
                    })
                }
              }).catch(error => {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: errorFormatter(error, '서버 응답 오류로 로그인에 실패하였습니다. 잠시 후에 다시 시도해 주세요.'),
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
            })
            .catch(error => {
              toast({
                component: ToastificationContent,
                props: {
                  title: errorFormatter(error, '로그인 과정에서 오류가 발생하였습니다.'),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    }

    return {
      required,
      status,
      password,
      userId,
      login,
      allowAccess,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
